.progress {
  position: absolute;
  top: $grid * 3;
  left: $grid * 3;

  &__title {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
  }

  &__subtitle {
    text-align: center;
    font-weight: bold;
    font-size: 1.25em;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: $grid / 2;
  }

  &__experience-value {
    text-align: center;
    font-weight: bold;
  }

  &__experience-required {
    text-align: center;
    margin-bottom: $grid;
  }

  &__experience-bar {
    width: 280px;
    height: $grid * 2;
    padding: 1px 0;
    margin: 0 $grid $grid;
    position: relative;
    display: block;
    

    &--background {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      background: rgb(128, 196, 255);
      height: 100%;
      width: 50%;
    }
  }

  &__level-up-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__level-up-button {
    font-family: $font-primary;
    font-weight: bold;
    font-size: 24px;
    margin: $grid 0 $grid*2;
    cursor: pointer;
  }

  &--light {
    .progress {
      &__experience-bar {
        background-color: lighten($lightmode-accent, 10%);
        border: 1px solid white;

        &--background {
          background: repeating-linear-gradient(to right, cyan 0%, blue 50%, cyan 100%);
          animation: ExperienceBarBackgroundMovement 3s infinite;
          animation-fill-mode: forwards;
          animation-timing-function: linear;
          background-size: 300% auto;
          background-position: 0 100%;
        }
      }

      &__level-up-button {
        background-color: $lightmode-accent;
        border-style: outset;
        border-radius: 99em;
        padding: $grid / 2 $grid;
        color: white;
        box-shadow: 0 0 0 0 rgb(255, 255, 255);
        transform: scale(1);
        animation: pulse--lightmode 2s infinite;
      }
    }
  }

  &--dark {
    .progress {
      &__title {
        color: $darkmode-white;
      }
    
      &__subtitle {
        color: $darkmode-white;
      }

      &__experience-value {
        color: $darkmode-white;
      }
    
      &__experience-required {
        color: $darkmode-white;
        padding-bottom: $grid;
      }

      &__experience-bar {
        background-color: $darkmode-black;
        border-radius: 5px;

        &--background {
          border-radius: 5px;
          background: repeating-linear-gradient(to right, $darkmode-accent 0%, $darkmode-accent-pair 50%, $darkmode-accent 100%);
          animation: ExperienceBarBackgroundMovement 3s infinite;
          animation-fill-mode: forwards;
          animation-timing-function: linear;
          background-size: 300% auto;
          background-position: 0 100%;
        }
      }

      &__level-up-button {
        background: linear-gradient(25deg, $darkmode-accent, $darkmode-accent-pair);
        background-size: 200%;
        background-position: 25% 25%;
        padding: $grid $grid * 2;
        border: none;
        color: $darkmode-white;
        border-radius: $grid;
        margin-top: 0;

        &:hover {
          font-weight: bold;
          box-shadow: inset 0 1px 3px #790753;
          background-position: 75% 75%;
        }

        box-shadow: 0 0 0 0 rgb(255, 255, 255);
        transform: scale(1);
        animation: pulse--darkmode 2s infinite;
      }
    }
  }

  &--glitch {
    .progress {
      &__title {
        color: $glitch-green;
      }
    
      &__subtitle {
        color: $glitch-green;
      }

      &__experience-value {
        color: $glitch-green;
      }
    
      &__experience-required {
        color: $glitch-green;
        padding-bottom: $grid;
      }

      &__experience-bar {
        background-color: $glitch-black;
        border-radius: $glitch-border-radius;

        &--background {
          border-radius: $glitch-border-radius;
          //background: repeating-linear-gradient(to right, $glitch-green 0%, $glitch-green 45%, $glitch-black 45%, $glitch-black 55%, $glitch-green 55%, $glitch-green 100%);
          background: repeating-linear-gradient(to right, $glitch-green 0%, $glitch-black 50%, $glitch-green 100%);
          animation: ExperienceBarBackgroundMovement 3s infinite;
          animation-fill-mode: forwards;
          animation-timing-function: linear;
          background-size: 300% auto;
          background-position: 0 100%;
        }
      }

      &__level-up-button {
        background: $glitch-black;
        border: $glitch-border;
        padding: $grid * 0.8 $grid * 1.2;
        margin-top: 0;
        color: $glitch-green;
        border-radius: $glitch-border-radius;

        &:hover {
          color: $glitch-black;
          background: $glitch-green;
          font-weight: bold;
          text-decoration: underline;
        }
        box-shadow: 0 0 0 0 rgb(255, 255, 255);
        transform: scale(1);
        animation: pulse--glitch 2s infinite;
      }
    }
  }

}

      // Code adapted from the following pen
      // https://codepen.io/dowding/pen/wJWpoY?editors=0100

@keyframes ExperienceBarBackgroundMovement { 
  0%   { 
    background-position: 0 0; 
  }
  100% { 
    background-position: -300% 0; 
  }
}

@keyframes pulse--lightmode {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@keyframes pulse--darkmode {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba($darkmode-accent, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba($darkmode-accent, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba($darkmode-accent, 0);
	}
}

@keyframes pulse--glitch {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba($glitch-green, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba($glitch-green, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba($glitch-green, 0);
	}
}