//Layout

$grid: 10px;

//Colour Scheme Variables

$lightmode-accent: #6eadff;

$darkmode-grey: #444;
$darkmode-light-grey: #666;
$darkmode-black: #222;
$darkmode-white: #f8f8f8;
$darkmode-accent: #ee2456;
$darkmode-accent-pair: #8c24ee;

$glitch-green: #00f500;
$glitch-black: #111;
$glitch-border: 1px solid $glitch-green;
$glitch-border-radius: 2px;

//Font Variables

$font-primary: Consolas, Monospace, serif;
$font-weights: ("regular": 400, "medium": 500, "bold": 700);
 
$mobile-font-sizes: (
  xxs: 8px,
  xs: 10px,
  s: 12px,
  m: 14px,
  l: 18px,
  xl: 22px,
  xxl: 28px,
  xxxl: 32px
);

$tablet-font-sizes: (
  xxs: 8px,
  xs: 10px,
  s: 12px,
  m: 18px,
  l: 18px,
  xl: 24px,
  xxl: 28px,
  xxxl: 48px
);

$desktop-font-sizes: (
  xxs: 10px,
  xs: 12px,
  s: 14px,
  m: 18px,
  l: 22px,
  xl: 24px,
  xxl: 32px,
  xxxl: 48px
);