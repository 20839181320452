.introduction {
  position: absolute;
  z-index: 998;
  top: 50vh;
  right: 50vw;
  transform: translate(50%, -50%) !important;
  text-align: center;

  &__background {
    position: absolute;
    top: 0; left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 997;
    background-color: black;
  }

  &__title {
    color: white;
    font-family: $font-primary;
    font-size: 36px;
  }

  &__subtitle {
    color: white;
    font-family: $font-primary;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: 22px;
  }

  &__instructions {
    color: white;
    font-family: $font-primary;
    font-size: 18px;
  }

  &__start-button {
    margin-top: $grid;
    color: black;
    background: white;
    border: 2px solid black;
    padding: 4px 8px;
    font-family: $font-primary;
    font-size: 18px;
    cursor: pointer;
    
    &:hover {
      color: white;
      background: black;
      border: 2px solid white;
    }
  }
}