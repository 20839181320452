.todo-list {
  position: absolute;
  top: $grid * 22;
  left: $grid * 8;

  width: max-content;
  min-width: 260px;

  &__container {
    padding: $grid;
  }

  &__label {
    display: block;
    padding: 5px;
    user-select: none;
    cursor: pointer;
  }

  &__struck {
    text-decoration: line-through;
    padding-left: 5px;
  }

  &__not-struck {
    padding-left: 5px;
  }

  &__encouragement {
    margin: 5px 0;
    text-align: center;
    font-weight: bold;
  }

  &--dark {
    .todo-list {
      &__container {
        color: $darkmode-white;
        padding: $grid $grid + 4px;
      }
    }
  }

  &--glitch {
    .todo-list {
      &__container {
        color: $glitch-green;
        padding: $grid $grid + 4px;
      }
    }
  }
}