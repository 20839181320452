.text-particle-system {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  &__particle {
    font-family: $font-primary;
    font-weight: bold;
    position: absolute;

    animation-name: evanesce;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &--positive[light] {
      color: green;
      text-shadow: 2px 2px 2px #fff, 2px 2px 2px #fff, 2px 2px 2px #fff;
    }

    &--positive[dark] {
      color: $darkmode-accent;
      background: $darkmode-black;
      border: 1px solid $darkmode-accent;
      padding: 2px 4px;
      border-radius: 5px;
    }

    &--positive[glitch] {
      color: $glitch-green;
      background: $glitch-black;
      padding: 2px 4px;
      border: $glitch-border;
    }

    &--negative[light] {
      color: red;
    }

    &--negative[dark] {
      color: $darkmode-accent-pair;
      background: $darkmode-black;
      border: 1px solid $darkmode-accent-pair;
      padding: 2px 4px;
      border-radius: 5px;
    }

    &--negative[glitch] {
      color: $glitch-black;
      background: $glitch-green;
      padding: 2px 4px;
      border: $glitch-border;
    }

    &--neutral[light] {
      color: grey;
    }

    &--neutral[dark] {
      color: $darkmode-white;
      background: $darkmode-black;
      border: 1px solid $darkmode-white;
      padding: 2px 4px;
      border-radius: 5px;
    }

    &--neutral[glitch] {
      color: $glitch-green;
      background: $glitch-black;
      padding: 2px 4px;
      border: $glitch-border;
    }
    
  }
}


@keyframes evanesce {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}